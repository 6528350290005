import { getToken } from './auth-client';

export const fetchMedia = async ({ page, productId, search }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/media`;
  const token = await getToken();

  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      token: JSON.parse(token),
      query: search,
      productId,
      page,
    }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const fetchMediaByUuid = async ({ mediaId }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/media/${mediaId}`;
  const token = await getToken();
  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      token: JSON.parse(token),
    }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const fetchSimilarMedia = async ({ asset, pimReferenceIds }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/media`;
  const token = await getToken();

  const requests = [];
  pimReferenceIds.forEach((pimReferenceId) => {
    requests.push(
      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          token: JSON.parse(token),
          query: {
            property_PimReferences: pimReferenceId,
          },
        }),
      }).then((res) => res.json())
    );
  });

  try {
    let media = [];
    const allRes = await Promise.all(requests);

    allRes.forEach((res) => {
      if (res.media) {
        media = [...res.media, ...media];
      }
    });

    media = media.reduce((unique, item) => {
      const existing = unique.find((uniqueItem) => uniqueItem.id === item.id);

      if (existing || item.id === asset.id) {
        return unique;
      }

      return [...unique, item];
    }, []);

    return Promise.resolve(media);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveMedia = async ({
  formats = [],
  asset,
  role,
  pimReference,
  objectType = null,
  masterId,
  locales = [],
}) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/upload`;
  const token = await getToken();

  const derivatives = formats
    .filter((format) => !!format.existing)
    .map(({ id, edited = false, existing: { ...params } }) => {
      return {
        id,
        edited,
        ...params,
      };
    });

  const body = {
    uuid: asset.id,
    imageUrl: asset.original || asset.thumbnails.webimage,
    productId: pimReference,
    derivatives,
    role,
    objectType,
    masterId,
    locales,
    token: JSON.parse(token),
  };

  const upload = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  const uploadRes = await upload.json();

  return uploadRes;
};

export const fetchDerivatives = async ({ roleKey, uuid }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/derivatives/${uuid}/formats/${roleKey}`;
  const token = await getToken();

  const body = {
    token: JSON.parse(token),
  };

  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const { formats } = await res.json();

  return formats;
};

export const savePriceList = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/price-list`;

  const body = { ...data };

  const save = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return await save.json();
};

export const saveRequestMaterialNumber = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/requestmaterialnumber`;

  const body = { ...data };

  const save = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return await save.json();
};

export const fetchProducts = async ({ id }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/product-search`;
  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const fetchProductCategories = async ({ id }) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/product-categories`;
  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  console.log("getting "+id);
  //console.dir(res.json());
  return res.json();
};
