/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect } from '@reach/router';
import React, { useMemo, useRef, useState } from 'react'; // eslint-disable-line no-unused-vars
import Cropper from 'react-cropper';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useQueryParam } from 'use-query-params';
import { useObjectType } from '../context/object-type-context';
import { useRole } from '../context/role-context';
import { fetchDerivatives, saveMedia } from '../lib/client-api';
import Button from '../components/button';
import Layout from '../components/layout';
import LoadingIndicator from '../components/loading-indicator';

import 'cropperjs/dist/cropper.css';
import { useLocale } from '../context/locale-context';

const Toolbar = ({
  active,
  formats,
  isPending,
  onCropSave,
  onFormatClick,
  onSave,
}) => {
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          display: flex;

          > * + * {
            margin-left: 30px;
          }
        `}
      >
        {formats &&
          formats.map((format, index) => {
            const isSet = !!format.existing;
            const isActive = index === active;

            return (
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  height: 100px;
                  width: 125px;
                `}
                key={format.id}
              >
                <Button
                  css={{
                    border: isActive && '2px dashed #fff',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    width: '100%',
                  }}
                  icon={isSet ? <IoIosCheckmarkCircleOutline /> : null}
                  onClick={() => onFormatClick(index)}
                >
                  <strong>{format.title}</strong>
                  <br />
                  {`${format.width}x${format.height}`}
                </Button>
              </div>
            );
          })}
      </div>
      <div
        css={css`
          margin-left: auto;
          margin-right: 30px;
        `}
      >
        <Button onClick={onCropSave}>Save format</Button>
      </div>
      <Button icon={isPending && <LoadingIndicator />} onClick={onSave}>
        All done
      </Button>
    </div>
  );
};

const Crop = ({ data, isLoading, masterId, navigate }) => {
  const { role } = useRole();
  const { locales } = useLocale();

  const { data: formats } = useQuery(
    ['fetchDerivatives', role?.value, data?.id],
    () => fetchDerivatives({ roleKey: role.value, uuid: data.id }),
    {
      enabled: !!role && !!data?.id,
      retry: false,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const mutation = useMutation((data) => saveMedia(data), {
    onSuccess: () => {
      toast('Successfully saved.');

      if (productId) {
        navigate(`../../../?productid=${pimReference}`);
      } else if (groupId) {
        navigate(`../../../?groupid=${pimReference}`);
      } else {
        navigate('../../../');
      }
    },
  });

  const objectType = useObjectType();

  const cropperEl = useRef(null);
  const [formatId, setFormatId] = useState(0);

  const aspectRatio = useMemo(() => {
    if (!formats) {
      return;
    }

    const selectedFormat = formats[formatId];

    if (!selectedFormat) {
      return NaN;
    }

    return selectedFormat.width / selectedFormat.height;
  }, [formats, formatId]);

  function handleCropSave() {
    queryClient.setQueryData(
      ['fetchDerivatives', role?.value, data.id],
      (oldFormats) => {
        const newFormats = [...oldFormats];

        newFormats[formatId] = {
          ...newFormats[formatId],
          edited: true,
          existing: {
            uuid: newFormats[formatId].existing?.uuid || null,
            ...cropperEl.current.cropper.getData(),
          },
        };

        return newFormats;
      }
    );
  }

  function handleFormatClick(id) {
    setFormatId(id);
  }

  function handleSave() {
    mutation.mutate({
      formats,
      asset: data,
      role: role.value,
      pimReference,
      objectType,
      masterId,
      locales: locales.map((locale) => locale.value).join(','),
    });
  }

  if (!role) {
    let to = null;

    if (productId) {
      to = `../../../?productid=${pimReference}`;
    } else if (groupId) {
      to = `../../../?groupid=${pimReference}`;
    } else {
      to = '../../../';
    }

    return <Redirect to={to} noThrow />;
  }

  return (
    <Layout
      title="Create or edit styles for selected asset"
      toolbar={
        <Toolbar
          active={formatId}
          formats={formats}
          isPending={mutation.isLoading}
          onFormatClick={handleFormatClick}
          onCropSave={handleCropSave}
          onSave={handleSave}
        />
      }
    >
      {formats && formats.length ? (
        <Cropper
          style={{ height: 400, width: '100%' }}
          aspectRatio={aspectRatio}
          data={formats[formatId].existing}
          ref={cropperEl}
          src={data.original || data.thumbnails.webimage}
        />
      ) : null}
    </Layout>
  );
};

export default Crop;
